<div class="pl-quick-links-title">
  <span class="title-text">Quick links</span>
</div>
<div class="pl-quick-links-container">
  <div
    *ngIf="showOrgHandbooks && organizations.length > 0"
    class="pl-links-align"
  >
    <div class="pl-link-icon handbook">
      <mat-icon fontSet="material-symbols-outlined">menu_book</mat-icon>
    </div>
    <div class="pl-handbook-link-info">
      <span class="pl-links-title-text">Organization Handbooks</span>
      <span class="text"
        >Access relevant org handbooks
        <ul>
          <li *ngFor="let organization of organizations">
            <a
              id="handbook-link-text"
              [routerLink]="['/organization', organization.id, 'handbook']"
              target="_blank"
            >
              {{ organization.name }}
            </a>
          </li>
        </ul>
      </span>
    </div>
  </div>
  <div class="pl-links-align">
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSdmFlqmjY3rpDDP6R4u6Bl2OtBRylxDNJF58BeoQZhbzTOlbQ/viewform"
      target="_blank"
      ><div class="pl-link-icon time-off">
        <mat-icon fontSet="material-symbols-outlined">access_time</mat-icon>
      </div></a
    >
    <div class="pl-time-off-link-info">
      <span class="pl-links-title-text">Provider Time-Off</span>
      <div>
        <span class="text"
          >Complete a
          <a
            id="time-off-link-text"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdmFlqmjY3rpDDP6R4u6Bl2OtBRylxDNJF58BeoQZhbzTOlbQ/viewform"
            target="_blank"
          >
            Provider Time-Off form
          </a>
          for each district affected by your absence
        </span>
      </div>
    </div>
  </div>
  <div class="pl-links-align">
    <div class="pl-link-icon online-store">
      <mat-icon fontSet="material-symbols-outlined">store</mat-icon>
    </div>
    <div class="pl-equipment-link-info">
      <span class="pl-links-title-text">Online Store & equipment</span>
      <ul>
        <li>
          <span class="text">
            <a
              id="online-store-link-text"
              href="https://go.ernestco.co/Login/assessmentspl"
              target="_blank"
            >
              Online Store
            </a>
            - for Assessment catalogs & toolkits
          </span>
        </li>
        <li>
          <span class="text">
            <a
              id="equipment-link-text"
              href="{{ equipmentOrderUrl }}"
              target="_blank"
            >
              Equipment order form
            </a>
            - for stimulus or hardware equipment
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="pl-links-align">
    <div class="pl-link-icon external-tools">
      <mat-icon fontSet="material-symbols-outlined">link</mat-icon>
    </div>
    <div class="pl-equipment-link-info">
      <span class="pl-links-title-text">External tools</span>
      <span class="text">Commonly accessed third-party assessment tools</span>
      <ul>
        <li>
          <a
            href="https://qglobal.pearsonassessments.com/qg/login.seam"
            target="_blank"
          >
            Q-Global
          </a>
        </li>
        <li>
          <a href="https://www.wjscore.com/WJIV/Home/Index/2" target="_blank">
            WJ Score
          </a>
        </li>
        <li>
          <a
            href="https://platform.wpspublish.com/account/login"
            target="_blank"
          >
            Western Psychological Services
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
